import React, {useEffect, useState} from 'react';
import {Card, DatePickerInput} from 'wix-ui-tpa/cssVars';
import {TimeSlotOption} from '../../../../domain/models/ShippingOption.model';
import {classes} from './ScheduledDeliverySelector.st.css';

export enum TimeSlotSelectorDataHook {
  root = 'TimeSlotSelectorDataHook.root',
  dropdown = 'TimeSlotSelectorDataHook.dropdown',
}

export interface TimeSlotSelectorProps {
  timeSlotDays: {[dateString: string]: TimeSlotOption[]};
  selectedId?: string;
  disabled?: boolean;
  onSelect: (shippingOptionId: string) => void;
}

export const TimeSlotSelector = ({timeSlotDays, onSelect, disabled, selectedId}: TimeSlotSelectorProps) => {
  const findOptionById = () => {
    return Object.keys(timeSlotDays)
      .flatMap((day) => timeSlotDays[day])
      .find((option) => option.id === selectedId);
  };

  const [selectedOption, setSelectedOption] = useState(findOptionById);

  useEffect(() => setSelectedOption(findOptionById()), /* eslint-disable react-hooks/exhaustive-deps */ [selectedId]);

  return (
    <div className={classes.root}>
      <Card data-hook={TimeSlotSelectorDataHook.root} className={classes.card}>
        <DatePickerInput
          disabled={disabled}
          value={selectedOption?.date}
          onChange={
            /* istanbul ignore next */ (date) => {
              onSelect(timeSlotDays[new Date(date).toDateString()][0].id);
            }
          }
          filterDate={/* istanbul ignore next */ (date) => Boolean(timeSlotDays[date.toDateString()])}
          removeClearButton={true}
          data-hook={TimeSlotSelectorDataHook.dropdown}
        />
      </Card>
    </div>
  );
};
